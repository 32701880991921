<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
pre_delivery_in_house.main_title : "ใบผลทดสอบโดรน"
pre_delivery_in_house.number_title : "เลขที่เอกสาร: "
pre_delivery_in_house.service_number : "{serviceNumber}"
</i18n>

<template>
	<a-modal class="printing-modal" :width="800" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleCancel">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('pre_delivery.drone.test.result.pageLoading.tip')">
			<div id="print-pre-delivery">
				<div class="page-header">
					<img class="hg-logo" src="@assets/images/hiveground_logo.png"/>
					<div class="modal-name">
						<my-page-header :title="$t('pre_delivery_in_house.main_title')" />
					</div>
					<div v-if="setFormData" class="service-no">
						<div style="font-size: 13px;" class="service-no-title">{{$t('pre_delivery_in_house.number_title')}}</div>
						<div style="font-size: 13px;">{{$t('pre_delivery_in_house.service_number', { serviceNumber: serviceNumber })}}</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_form_title') }}</span>
							{{ $t('service.header.page_form_content', { form: setFormData.formCode }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_revision_title') }}</span>
							{{ $t('service.header.page_revision_content', { revision: setFormData.revision }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_effective_date_title') }}</span>
							{{ $t('service.header.page_effective_date_content', { effectiveDate: setFormData.effectiveDate }) }}
						</div>
					</div>
				</div>

				<div class="service-detail-container">
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.company_info') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :value="displayCompany.contact_phone" />
							</div>
							<div v-if="displayCompany.contact_email" class="service-val-row info">
								{{ displayCompany.contact_email }}
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.model', { name: droneModelName }) }}
							</div>
							<div class="service-val-row">
									{{ $t('service.header.drone_owner', { name: ownerName }) }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div v-if="isSamePerson" class="service-val">
							<div class="service-val-row">
								{{ ownerName }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
						<div v-else class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row" style="display: flex">
								{{ $t('service.header.drone_owner_identity_no_blank')}}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="customerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime">
									{{ droneReceiveDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.inspection_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="inspectedDateTime">
									{{ inspectedDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.flight_time') }}</label>
						<div class="service-val">
							<FlightTimeView :time-seconds="flightTime" />
						</div>
					</div>
				</div>

				<BlockPane :block="isNotInspect">
					<span slot="message">
						{{$t('pre_delivery.field.is_not_inspect.message')}}
					</span>
					<PreDeliveryTGD1XChecklistPrint ref="checklistRef" :can-update="false" :service="service" />
				</BlockPane>
				<div class="consent" v-html="$t('pre_delivery.footer.consent.general')" />
				<div v-html="$t('pre_delivery.footer.consent.insurance')" />
				<div v-html="$t('pre_delivery.footer.consent.acceptance')" />
				<div class="signature">
					<div
						class="signature-item"
						v-html="$t('pre_delivery.footer.signature', { description: $t('pre_delivery.footer.signature.customer') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_delivery.footer.signature', { description: $t('pre_delivery.footer.signature.inspect_by') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_delivery.footer.signature', { description: $t('pre_delivery.footer.signature.deliver_by') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_delivery.footer.signature', { description: $t('pre_delivery.footer.signature.approve_by') })"/>
				</div>
				<div class="page-remark">
					{{ $t('pre_delivery.footer.remark') + (remark ? remark : '') }}
				</div>			</div>
			<a-button type="info" icon="printer" @click="print"> print </a-button>
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import PreDeliveryTGD1XChecklistPrint from '@components/service/tgd1x/PreDeliveryTGD1XChecklistPrint.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import BlockPane from "@components/common/BlockPane.vue"
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import { findFormData, FORM_CODE } from '@utils/documentUtil'

export default {
	components: {
		PreDeliveryTGD1XChecklistPrint,
		PhoneLink,
		FlightTimeView,
		BlockPane
	},

	props: {
		service: {
			type: null,

			default: () => [],
		},

		preDelivery: {
			type: null,

			default: () => {},
		},

		isNotInspect: {
			type: Boolean,
			default: false,
		}

	},

	data() {
		return {
			visible: false,
			loading: false,
			flightTime: '',
			droneReceiveDateTime: '',
			inspectedDateTime: '',
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			remark: '',
			ownerName: '',
			ownerIdentityNo: null,
			ownerPhone: '',
			ownerProvince: '',
			isSamePerson: false,
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('form', ['forms']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
		setFormData() {
			const formCode = FORM_CODE.PRE_DELIVERY
			return findFormData(this.forms, formCode)
		}
	},

	methods: {
		setData(data) {
			this.$nextTick(() => {
				this.$refs.checklistRef.setData(data)
			})
		},
		_loadPreServcie() {
			this.loading = true
			axios
				.get('/api/services/' + this.serviceId + '/pre-service')
				.then((response) => {
					if (response.data.data.pre_service.inspected_datetime) {
						const date = this.$dayjs(response.data.data.pre_service.inspected_datetime)
						this.droneReceiveDateTime = date.format('YYYY-MM-DD') + ' เวลา ' + date.format('HH:mm')
					}
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
					this.visible = false
				})
				.finally(() => {
					this.loading = false
				})
		},

		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.flightTime = data.flightTime
			this.inspectedDateTime = data.inspectedDateTime
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.ownerName = data.owner.name
			this.ownerIdentityNo =  data.owner.identity_no
			this.ownerPhone = data.owner.phone
			this.ownerProvince = data.owner.province
			this.isSamePerson = this.customerName === this.ownerName
			this.remark = data.remark
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			this._loadPreServcie()
		},

		handleCancel() {
			this.visible = false
		},

		print() {
			const prtHtml = document.getElementById('print-pre-delivery').innerHTML

			// Get all stylesheets HTML
			let stylesHtml = ''

			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
				stylesHtml += node.outerHTML
			}

			// Open the print window
			const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
			WinPrint.document.write(`<!DOCTYPE html>

				<html>
  				<head>
    				${stylesHtml}
  				</head>
  				<body>
    				${prtHtml}
  				</body>
				</html>`)

			setTimeout(()=> {
				WinPrint.focus()
				WinPrint.document.close()
				WinPrint.print()
				WinPrint.close()
			}, 2000)
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;

.printing-modal {
	width: 700px;
	div {
		font-size: 0.7rem;
	}
}
.info {
	color: @info-color;
}
.page-header {
	display: flex;
	flex-direction: row;
	padding-top: 15px;
  justify-content: space-between;
}
.service-no {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-left: 30px;
}
.service-no-title {
	color: @primary-color;
}
.form-detail {
	font-style: italic;
}
.modal-name {
	justify-content: center;
	align-items: center;
	text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;

	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			flex-grow: 1;
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.consent {
	margin-top: 16px;
}
.signature {
	display: flex;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		flex-basis: 25%;
		align-items: center;
		flex-direction: column;
		position: relative;
		margin-right: 8px;
	}
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
@media print {
	div {
		font-size: 0.7rem;
	}

	.service-col {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
}
</style>
