<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>

<template>
	<div v-show="!loading" class="page-padding">
		<template v-if="canUpdatePreDelivery">
			<div class="test-result-container">
				<a-button v-if="!service.is_device_only" icon="printer" @click="openPrintModal">
					{{$t('pre_delivery.drone.test.result.button')}}
				</a-button>
				<a-button class="right-button" @click="handleReset">
					{{$t('pre_delivery.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="handleSave">
					{{$t('common.save')}}
				</a-button>
			</div>

			<ErrorMessagePane :error="saveError" />

			<div class="pre_delivery-not-inspect">
				<a-checkbox
					v-model="preDeliveryValue.is_not_inspect">
					{{$t('pre_delivery.field.is_not_inspect')}}
				</a-checkbox>
			</div>
			<hr />
		</template>

		<div class="mytab-section-title">
			{{$t('pre_delivery.drone')}}
		</div>
		<div v-if="service.is_device_only" class="pre_delivery-subsection">
			{{$t('pre_delivery.drone.none')}}
		</div>
		<div v-else class="pre_delivery-subsection">
			<div class="pre_delivery-row">
				<label>{{$t('pre_delivery.field.flight_time')}} * : </label>
				<div v-if="canUpdatePreDelivery" class="pre_delivery-input-block">
					<FlightTimeInput
						v-model="preDeliveryValue.flight_time_sec"/>
				</div>
				<div v-else class="pre_delivery-input-view">
					<FlightTimeView :time-seconds="preDeliveryValue.flight_time_sec" />
				</div>
			</div>
		</div>

		<BlockPane :block="preDeliveryValue.is_not_inspect">
			<span slot="message">
				{{$t('pre_delivery.field.is_not_inspect.message')}}
			</span>

			<div class="mytab-section-title">
				{{$t('pre_delivery.inspect')}}
			</div>
			<div class="pre_delivery-subsection">
				<div class="pre_delivery-row">
					<label>{{$t('pre_delivery.field.inspected_by')}} * : </label>
					<div v-if="canUpdatePreDelivery" class="pre_delivery-input-block">
						<UserCompanySelect
							v-model="preDeliveryValue.inspected_by_id"
							:company-id="service.company_id"
							:allow-clear="true"
							:placeholder="$t('pre_delivery.field.inspected_by.placeholder')"/>
					</div>
					<div v-else class="pre_delivery-input-view">
						<my-router-link v-if="preDelivery.inspected_by_id > 0" name="user/view" :param-value="preDelivery.inspected_by_id">
							<ImageName :name="preDelivery.inspectedByData.user.username" :src="preDelivery.inspectedByData.user.image_url">
								<a-icon type="user" />
							</ImageName>
						</my-router-link>
						<span v-else>{{$t('common.unspecify')}}</span>
					</div>
				</div>
				<template v-if="canUpdatePreDelivery">
					<div class="pre_delivery-row">
						<label>{{$t('pre_delivery.field.inspected_date')}} * : </label>
						<div class="pre_delivery-input-block">
							<MyDatePicker
								v-model="inspectedDate"
								format="DD MMMM YYYY"/>
						</div>
					</div>
					<div class="pre_delivery-row">
						<label>{{$t('pre_delivery.field.inspected_time')}} * : </label>
						<div class="pre_delivery-input-block">
							<a-time-picker
								v-model="inspectedTime"
								input-read-only
								format="HH:mm"
								value-format="HH:mm"/>
						</div>
					</div>
				</template>
				<div v-else class="pre_delivery-row">
					<label>{{$t('pre_delivery.field.inspected_date')}} * : </label>
					<div class="pre_delivery-input-view">
						<span v-if="$notEmpty(preDelivery.inspected_datetime)">
							{{$dayjs(preDelivery.inspected_datetime).format('LLL')}}
						</span>
						<span v-else>
							{{$t('common.unspecify')}}
						</span>
					</div>
				</div>
			</div>

			<template v-if="!service.is_device_only">
				<div class="mytab-section-title">
					{{$t('pre_delivery.field.checklist')}}
				</div>

				<div class="pre_delivery-subsection-full">
					<component
						:is="checklistComponent"
						ref="checklist"
						:can-update="canUpdatePreDelivery"
						:service="service"
						:checklist-type="preDelivery.checklist_type"/>
					<ServicePreDeliveryPrintModal
						ref="preDeliveryPrintModalRef"
						:is-not-inspect="preDeliveryValue.is_not_inspect"
						:pre-delivery="preDelivery"
						:service="service"/>
				</div>
			</template>
		</BlockPane>
		<template v-if="canUpdatePreDelivery || serviceFiles.length > 0 || serviceDocumentFiles.length > 0">
			<div class="mytab-section-title">
				{{$t('pre_delivery.files')}}
			</div>
			<div class="pre_delivery-subsection files-upload">
				<div class="files-component">
					<ServiceFilesPanel
						ref="serviceFilesRef"
						:error="componentError"
						:service="service"
						:label="$t('pre_delivery.images.label')"
						:others-label="$t('pre_delivery.others.label')"
						:can-update="canUpdatePreDelivery"/>
				</div>
				<div class="files-component">
					<ServiceFilesPanel
						ref="serviceDocumentFilesRef"
						:error="componentError"
						:service="service"
						:label="$t('pre_delivery.document_images.label')"
						:others-label="$t('pre_delivery.document_files.label')"
						:can-update="canUpdatePreDelivery"/>
				</div>
			</div>
		</template>
		<div class="mytab-section-title">
			{{$t('pre_delivery.field.remark')}}
		</div>
		<div class="pre_delivery-subsection-full">
			<a-textarea
				v-model="preDeliveryValue.remark"
				style="max-width:800px;"
				:auto-size="{ minRows: 4,maxRows: 10 }"
				:read-only="!canUpdatePreDelivery"
				:placeholder="$t('pre_delivery.field.remark.placeholder')"/>
		</div>
		<div v-if="canUpdatePreDelivery" class="page-action-right">
			<a-button @click="handleReset">
				{{$t('pre_delivery.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="handleSave">
				{{$t('common.save')}}
			</a-button>
		</div>

	</div>
</template>

<script>
import ServicePreDeliveryPrintModal from '@components/service/ServicePreDeliveryPrintModal.vue'
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@mixins/service/ServiceObjectMixin.vue"
import {transformToJsonProp,replaceDeepProperty} from "@utils/objectUtil"
import {Checkbox,TimePicker,Input} from "ant-design-vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import UserCompanySelect from "@components/user/UserCompanySelect.vue"
import BlockPane from "@components/common/BlockPane.vue"
import ImageName from "@components/common/ImageName.vue"
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import FlightTimeView from "@components/common/FlightTimeView.vue"
import InvalidChecklistType from "@components/service/InvalidChecklistType.vue"
import PreDeliveryTGD1XChecklist from "@components/service/tgd1x/PreDeliveryTGD1XChecklist.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import {scrollTopContent} from "@utils/formUtil"
import axios from "axios"
import _isEqual from "lodash/isEqual"
import ModelError from "@utils/errors/ModelError"
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"


function _defaultPreDelivery() {
	return {
		is_not_inspect : false,
		flight_time_sec : undefined,
		inspected_by_id : undefined,
		remark : undefined,
	}
}

export default {
	components : {
		"a-checkbox" : Checkbox,
		"a-time-picker" : TimePicker, "a-textarea" : Input.TextArea,
		FlightTimeView, FlightTimeInput ,
		InvalidChecklistType ,
		PreDeliveryTGD1XChecklist ,
		UserCompanySelect, MyDatePicker, BlockPane , ImageName,
		ServiceFilesPanel ,
		ErrorMessagePane,
		ServicePreDeliveryPrintModal,
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			preDelivery : {} ,
			serviceFiles: [],
			serviceDocumentFiles: [],

			preDeliveryValue : _defaultPreDelivery(),
			inspectedDate : undefined ,
			inspectedTime : undefined,

			ignorePreventExit : false,
			oldFormData : {} ,

			saveError : undefined,
		}
	} ,
	computed : {
		checklistComponent() {
			if (this.service.id > 0) {
				switch (this.preDelivery.checklist_type) {
					case 'tgd1x' :
						return PreDeliveryTGD1XChecklist
					default :
						return InvalidChecklistType
				}
			}
			return undefined
		},
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.preDelivery || newVal.params.id != this.preDelivery.id) {
				this.fetchPreDelivery()
			}
		}
	} ,
	mounted() {
		this.fetchPreDelivery();
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		openPrintModal () {
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
					this.$refs.preDeliveryPrintModalRef.openModal({
						serviceId: this.service.id,
						flightTime: this.preDeliveryValue.flight_time_sec,
						inspectedDateTime: this.preDelivery.inspected_datetime ? this.inspectedDate + ' เวลา ' + this.inspectedTime : 'ไม่ระบุ',
						serviceNumber: this.service.service_no,
						droneSerial: this.service.droneData.drone.serial_no,
						dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
						droneModelName: this.service.droneData.model.name,
						customerName: this.service.customer_name,
						customerPhone: this.service.customer_phone,
						customerProvince: this.service.customer_province,
						owner: this.service.droneData.owner,
						technician: this.service.technicianData ? this.service.technicianData.user.full_name : '',
						remark: this.preDeliveryValue.remark
					})

					this.$refs.preDeliveryPrintModalRef.setData(checklistC.getData())
			}
		},
		fetchPreDelivery() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			if (!this.canViewPreDelivery) {
				return this.$open({name : 'service/view',params: {id : serviceId}})
			}
			this.loading = true
			axios.get("/api/services/"+serviceId+"/pre-delivery").then((response) => {
				this.preDelivery = response.data.data.pre_delivery
				this.serviceFiles = response.data.data.service_files
				this.serviceDocumentFiles = response.data.data.service_document_files

				this.initPreDelivery()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		} ,
		initPreDelivery() {
			this.saveError = undefined
			this.inspectedDate = undefined
			this.inspectedTime = undefined

			this.preDeliveryValue = _defaultPreDelivery()
			if (this.preDelivery) {
				replaceDeepProperty(this.preDeliveryValue,this.preDelivery)
			}
			if (this.preDelivery.inspected_datetime) {
				const date = this.$dayjs(this.preDelivery.inspected_datetime)
				this.inspectedDate = date.format('YYYY-MM-DD')
				this.inspectedTime = date.format('HH:mm')
			}


			this.$nextTick(() => {
				const checklistComp = this.$refs.checklist
				if (checklistComp && typeof checklistComp.setData === 'function') {
					if (this.preDelivery.checklistData) {
						checklistComp.setData(this.preDelivery.checklistData)
					} else {
						checklistComp.setData({})
					}
				}
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
				this.$nextTick(() => {this.oldFormData = this.dumpFormData()})
			})
		} ,
		dumpFormData() {
			const formData = {
				id : this.preDelivery.id,
				...this.preDeliveryValue ,
				service_files: []
			}
			if (formData.inspected_by_id === undefined) {
				formData.inspected_by_id = null
			}
			if (this.$notEmpty(this.inspectedDate) && this.$notEmpty(this.inspectedTime)) {
				formData.inspected_datetime = this.inspectedDate + ' ' + this.inspectedTime+":00"
			} else {
				if (this.$notEmpty(this.inspectedDate)) {
					formData.inspected_datetime = this.inspectedDate + ' 10:00:00'
				} else {
					formData.inspected_datetime = null
				}
			}
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
				formData.checklistData = checklistC.getData()
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'pre-delivery', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'pre-delivery-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdatePreDelivery)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}

			this.$confirm({
				title : this.$t('pre_delivery.action.ask_reset.title') ,
				content : this.$t('pre_delivery.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initPreDelivery()
					scrollTopContent()
				} ,
			})
		} ,
		handleSave() {
			if (!this.canUpdatePreDelivery)
				return
			const preFormData = this.dumpFormData()
			if (!this.isDataChange(preFormData)) {
				this.$message.info(this.$t('pre_delivery.action.update.no_change'))
				return
			}

			const serviceFiles = preFormData.service_files
			delete preFormData.service_files

			this.$confirm({
				title : this.$t('pre_delivery.action.update.confirm.title') ,
				content : this.$t('pre_delivery.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					// transform formData
					transformToJsonProp(preFormData,'checklistData','checklist_json')

					const formData = {
						'pre_delivery' : preFormData ,
						'service_files' : serviceFiles
					}
					axios.put("/api/services/"+this.service.id+"/pre-delivery",formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('pre_delivery.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
						scrollTopContent()
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,

		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdatePreDelivery || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
				this.$confirm({
				title : this.$t('pre_delivery.action.ask_leave.title') ,
				content : this.$t('pre_delivery.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.pre_delivery-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile & {
		margin-left : 16px;
	}
}
.pre_delivery-subsection-full {
	margin-bottom : 32px;
}

.pre_delivery-row {
	margin-bottom : 12px;
	label {
		color : @info-color;
		.mobile & {
			margin-bottom : 4px;
		}
	}

}
.pre_delivery-input-view {
	margin-left : 8px;
	display : inline-block;
}
.pre_delivery-input-block {
	display : inline-block;
	margin-right : 8px;
	.mobile & {
		display : block;
		margin-bottom : 4px;
		margin-right : 0;
	}
}
.test-result-container {
	display: flex;
	margin-bottom: 12px;
	flex-direction : row;
	justify-content : space-between;
}
.right-button {
	margin: 0 0.2rem 0 auto;
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
</style>
